import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import DiscordLogo from '../image/Discord-Logo.png';

const CustomBar = props => {
    return (
      <React.Fragment>
        <AppBar
        position="fixed"
        color="secondary"
        className={`footerbar border-${props.theme.palette.type}`}
        >
          <Toolbar>
            <Button
              color="inherit"
              size="small"
              href="https://discord.com/invite/Y9RDUuKfUN"
              target="_blank"
              rel="noopener noreferrer"
            >
                <img
                src={DiscordLogo}
                alt="ディスコードロゴ"
                id="discordLogo"
                className={`logo-${props.theme.palette.type}`}
                />
            </Button>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
};
  
export default CustomBar;