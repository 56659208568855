import React, { useContext } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { ThemeContext } from "../theme/themeContext";
import Typography from '@material-ui/core/Typography';
import Logo from '../image/GESOTITLE.png';

const CustomBar = props => {
    const themeContext = useContext(ThemeContext);
  
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleMenuClick = themeName => {
      themeContext.handleThemeChange(themeName);
      handleClose();
    };
    return (
      <React.Fragment>
        <AppBar
        position="fixed"
        className={`appbar border-${props.theme.palette.type}`}
        >
          <Toolbar>
            <img
            src={Logo}
            alt="サイトロゴ"
            id="logo"
            className={`logo-${props.theme.palette.type}`}
            />
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              color="inherit"
              onClick={handleClick}
            >
              <Typography variant="button">カラー変更</Typography>
            </Button>
          </Toolbar>
        </AppBar>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleMenuClick("DEFAULT")}>
            デフォルト
          </MenuItem>
          <MenuItem onClick={() => handleMenuClick("DARK")}>ダーク</MenuItem>
          <MenuItem onClick={() => handleMenuClick("NAVY")}>ネイビー</MenuItem>
        </Menu>
      </React.Fragment>
    );
};
  
export default CustomBar;