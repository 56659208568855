import React, { useContext, useEffect } from "react";

import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";

import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  Sorting,
  WithSearch
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import CustomBar from "./components/CustomBar";
import FooterBar from "./components/FooterBar.js"
import { ThemeContext } from "./theme/themeContext";
import { appendClassName, getUrlSanitizer } from "./elastic-scripts/view-helpers";
import { isFieldValueWrapper } from "./elastic-scripts/types/FieldValueWrapper.js";
import Typography from '@material-ui/core/Typography';
import Select from "react-select";

import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  buildSortOptionsFromConfig,
  getConfig,
  getFacetFields
} from "./config/config-helper";

const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();
const connector = new AppSearchAPIConnector({
  searchKey,
  engineName,
  hostIdentifier,
  endpointBase
});

const config = {
  searchQuery: {
    facets: buildFacetConfigFromConfig(),
    ...buildSearchOptionsFromConfig()
  },
  autocompleteQuery: buildAutocompleteQueryConfig(),
  apiConnector: connector,
  alwaysSearchOnInitialLoad: true,
  initialState: {
    resultsPerPage: 5,
    sortDirection: "desc",
    sortField: "createdat"
  }
};

function getEscapedField(result, field) {
  const safeField =
    getSnippet(result, field) || htmlEscape(getRaw(result, field));
  return Array.isArray(safeField) ? safeField.join(", ") : safeField;
}

function getEscapedFields(result) {
  return Object.keys(result).reduce((acc, field) => {
    if (!isFieldValueWrapper(result[field])) return acc;
    return { ...acc, [field]: getEscapedField(result, field) };
  }, {});
}

function getFieldType(result, field, type) {
  if (result[field]) return result[field][type];
}

function getRaw(result, field) {
  return getFieldType(result, field, "raw");
}

function getSnippet(result, field) {
  return getFieldType(result, field, "snippet");
}

function htmlEscape(str) {
  if (!str) return "";

  return String(str)
    .replace(/&/g, "&amp;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#39;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
}

const setDefaultStyle = {
  option: () => ({}),
  control: () => ({}),
  dropdownIndicator: () => ({}),
  indicatorSeparator: () => ({}),
  singleValue: provided => {
    // Pulling out CSS that we don't want
    // eslint-disable-next-line no-unused-vars
    const { position, top, transform, maxWidth, ...rest } = provided;
    return { ...rest, lineHeight: 1, marginRight: 0 };
  },
  valueContainer: provided => ({ ...provided, paddingRight: 0 })
};

const wrapOption = option => ({ label: option, value: option });

export default function App() {
  const context = useContext(ThemeContext);

  useEffect(() => {
    const themeName = localStorage.getItem("theme");
    if (themeName !== null) {
      context.handleThemeChange(themeName);
    }
  }, [context]);

  return (
    <ThemeProvider theme={context.theme}>
      <CssBaseline />
      <CustomBar theme={context.theme} />
      <div className={'container'}>
        <SearchProvider config={config}>
          <WithSearch mapContextToProps={({ wasSearched }) => ({ wasSearched })}>
            {({ wasSearched }) => {
              return (
                <div className="App">
                  <ErrorBoundary>
                    <Layout
                      header={
                        <SearchBox
                        autocompleteSuggestions={true}
                        inputView={({ getAutocomplete, getInputProps, getButtonProps }) =>  {
                          return (
                            <>
                            <div className="sui-search-box__wrapper">
                              <input
                              maxlength='30'
                                {...getInputProps({
                                  placeholder: "検索キーワード"
                                })}
                              />
                              {getAutocomplete()}
                            </div>
                            <input
                              {...getButtonProps({
                                "value": "検索"
                              })}
                            />
                          </>
                          )
                        }}
                        />
                      }
                      bodyContent={
                        <div>
                          <div>
                            {wasSearched && (
                              <Sorting
                                label={"並び替え"}
                                sortOptions={buildSortOptionsFromConfig()}
                              />
                            )}
                            {getFacetFields().map(field => (
                              <Facet key={field} field={field} label={field} />
                            ))}
                          </div>
                          <Results
                            titleField={getConfig().titleField}
                            urlField={getConfig().urlField}
                            shouldTrackClickThrough={true}
                            resultView={({className, result, onClickLink, titleField, urlField, ...rest}) => {
                              const fields = getEscapedFields(result);
                              const title = getEscapedField(result, titleField);
                              const url = getUrlSanitizer(URL, window.location)(getRaw(result, urlField));
                              return (
                                <li className={appendClassName(`sui-result-origin border-result-${context.theme.palette.type}`, className)} {...rest}>
                                  <div className="sui-result__header">
                                    {title && !url && (
                                      <span
                                        className="sui-result__title"
                                        dangerouslySetInnerHTML={{ __html: title }}
                                      />
                                    )}
                                    {title && url && (
                                      <a
                                        className={`sui-result__title sui-result__title-link-origin link-${context.theme.palette.type}`}
                                        href={url}
                                        onClick={onClickLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <Typography variant="h6" dangerouslySetInnerHTML={{ __html: title }} />
                                      </a>
                                    )}
                                  </div>
                                  <div className="sui-result__body">
                                    <ul className="sui-result__details-origin">
                                      {Object.entries(fields).map(([fieldName, fieldValue]) => {
                                        if (["createdat", "url"].indexOf(fieldName) === -1) return false
                                        if (fieldName === "createdat") {
                                          fieldName = "投稿日時"
                                          fieldValue = new Date(fieldValue).toLocaleString()
                                        }
                                        if (fieldName === "url") fieldName = "URL"
                                        return (
                                          <li key={fieldName}>
                                            <span className="sui-result__key">{fieldName}</span>
                                            <span
                                              className="sui-result__value"
                                              dangerouslySetInnerHTML={{ __html: fieldValue }}
                                            />
                                          </li>
                                        )}
                                      )}
                                    </ul>
                                  </div>
                                </li>
                                )
                              }
                            }
                          />
                        </div>
                      }
                      bodyHeader={
                        <React.Fragment>
                          {wasSearched &&
                          <PagingInfo
                          view={({ className, end, searchTerm, start, totalResults, ...rest }) => {
                            return (
                              <div className={appendClassName(`${context.theme.palette.type} sui-paging-info`, className)} {...rest}>
                                検索結果{" "}
                                <strong>
                                  {start} - {end}
                                </strong>{" "}
                                , <strong>{totalResults}</strong> 中
                                {searchTerm && (
                                  <>
                                    {" "}
                                    キーワード: <em>{searchTerm}</em>
                                  </>
                                )}
                              </div>
                            )
                          }}
                          />}
                          {wasSearched &&
                          <ResultsPerPage
                          options={[5, 10, 15]}
                          view={({onChange, options, className, value: selectedValue, ...rest}) => {
                            let selectedOption = null;
                            if (selectedValue) {
                              selectedOption = wrapOption(selectedValue);

                              if (!options.includes(selectedValue)) options = [selectedValue, ...options];
                            }
                            return (
                              <div
                                className={appendClassName(`${context.theme.palette.type} sui-results-per-page`, className)}
                                {...rest}
                              >
                                <div className="sui-results-per-page__label"><Typography variant="caption">表示件数</Typography></div>
                                <Select
                                  className="sui-select sui-select--inline"
                                  classNamePrefix="sui-select"
                                  value={selectedOption}
                                  onChange={o => onChange(o.value)}
                                  options={options.map(wrapOption)}
                                  isSearchable={false}
                                  styles={setDefaultStyle}
                                />
                              </div>
                            )
                          }}
                          />
                          }
                        </React.Fragment>
                      }
                      bodyFooter={
                        <Paging className={`paging-footer paging-typo-${context.theme.palette.type}`}/>
                      }
                    />
                  </ErrorBoundary>
                </div>
              );
            }}
          </WithSearch>
        </SearchProvider>
      </div>
      <FooterBar theme={context.theme} />
    </ThemeProvider>
  );
}
