import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import ThemeContextProvider from "./theme/themeContext";
import './css/main.css'

ReactDOM.render(
    <ThemeContextProvider>
        <App />
    </ThemeContextProvider>,
    document.getElementById("root")
);
